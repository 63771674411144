import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Banner01 from "../../images/banner_2.jpg"
import Banner02 from "../../images/img_768.jpg"
import Banner03 from "../../images/gallery_xl.jpg"
import gallerImg2 from "../../images/gallery_xl_2.jpg"
import gallerImg3 from "../../images/gallery_xl_3.jpg"
import gallerImg4 from "../../images/gallery_xl_4.jpg"
import gallerImg5 from "../../images/gallery_xl_5.jpg"
import OfferTag from "../Offertag/Offertag"
import PlayVideo from '../Play/PlayVideo';
import ScrollAnimation from 'react-animate-on-scroll';
import BookAppointment from "../BookAppointment/BookAppointment";
import { isIOS } from 'react-device-detect';
import { GetURL } from '../utils';
import "./Banner.scss"
import ImageTransform from "../common/ggfx-client/module/components/image-transform"
const settings = {
    dots: false,
    speed: 800,
    infinite: false,
    arrows: true,
    mobileFirst: true,
    centerMode: false,
    responsive: [
        {
            breakpoint: 3020,
            settings: {
                slidesToShow: 2.2,

            }
        },
        {
            breakpoint: 1500,
            settings: {
                slidesToShow: 2.1
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 1
            }
        },
        {
            breakpoint: 400,
            settings: {
                slidesToShow: 1
            }
        }
    ]
};
const DetailsBanner = (props) => {
    const [isPlay, setPlay] = useState(false);
    const [renderComponent, setRenderComponent] = useState(false);
    let processedImages = JSON.stringify({});
    useEffect(() => {
        if (isIOS) {
          setRenderComponent(true)
        }else {
        window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })}
    }, [])
    if (props?.data?.imagetransforms?.images_Transforms) {
        processedImages = props?.data.imagetransforms.images_Transforms
    }
    
    return (
        <React.Fragment>
           <div className="gallery-wrap">
                <div className="detail-banner">
                    <div className="banner-img">
                        {props?.openHouse && <OfferTag text="Open house"/>}
                        <a href="javascript:;" onClick={props.setIsOpen} className="cursor-pointer">
                        <ImageTransform
                            imagesources={props?.data?.images?.[0]?.url}
                            renderer="pic-src" imagename="properties.images.details_lg"
                            attr={{ alt: props?.data?.intro, className: 'bg-img m-0' }}
                            imagetransformresult={processedImages}
                            id={props?.data?._id}
                        />
                         </a>
                        {/* <img className="bg-img" src={props?.data?.images?.[0]?.url}/> */}
                        <div className="d-md-none banner-bar">
                            <span>1 of {props?.data?.images?.length}</span>
                        </div>
                        {props?.data?.virtual_tour?.length > 0 && <button type="button" className="play-btn" onClick={props.playVideo}>
                            <i className="video-icon"></i>
                        </button>}
                    </div>
                    <div className="banner-info">
                        <div className="link-wrapper">
                            <a href="javascript:;" onClick={props.setIsOpen} className="cursor-pointer">
                                <i className="icon-zoom-out"/>
                                    1/{props?.data?.images?.length} photos
                            </a>
                            <Link href="#" className="d-lg-none d-none">
                                <span>video</span>
                            </Link>
                            <a href="javascript:;" onClick={props.setIsOpenFloor} className="cursor-pointer">
                                <span>floorplans </span>
                            </a>
                            <a href="javascript:;" onClick={props.gotoLocation} className="cursor-pointer">
                                <span>location</span>
                            </a>
                            <a href="javascript:;" onClick={props.setIsOpenEPC} className="cursor-pointer">
                                <span>EPC</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="gallery-row">
                    <div className="gallery-block">
                        {/* <img src={props?.data?.images?.[1]?.url} alt=""/> */}
                        <a href="javascript:;" onClick={props.setIsOpen} className="cursor-pointer">
                        <ImageTransform
                            imagesources={props?.data?.images?.[1]?.url}
                            renderer="pic-src" imagename="properties.images.details_sm"
                            attr={{ alt: props?.data?.intro, className: 'm-0' }}
                            imagetransformresult={processedImages}
                            id={props?.data?._id}
                        />
                        </a>
                    </div>
                    <div className="gallery-block">
                        {/* <img src={props?.data?.images?.[2]?.url} alt=""/> */}
                        <a href="javascript:;" onClick={props.setIsOpen} className="cursor-pointer">
                         <ImageTransform
                            imagesources={props?.data?.images?.[2]?.url}
                            renderer="pic-src" imagename="properties.images.details_sm"
                            attr={{ alt: props?.data?.intro, className: 'm-0' }}
                            imagetransformresult={processedImages}
                            id={props?.data?._id}
                        />
                        </a>
                    </div>
                </div>
                <div className="appointment-section">
                    <div className="appointment-content">
                        <span className='sub-title'>{props?.data?.Sm_Title}</span>
                        <h2>Award winning property specialists in Kensington and Chelsea</h2>
                        <div className="btn-row">
                            {renderComponent && ( <>
                                {/* <ReapitBook className="btn" type="[&quot;sales&quot;,&quot;lettings&quot;]">{props?.data?.Cta_1_Label}</ReapitBook> */}
                                <Link to='/contact/property-valuation/' className="btn">BOOK A VALUATION</Link>
                                </>
                            )}
                        </div>
                    </div>
                </div>
           </div>
        </React.Fragment>
    )
}
export default DetailsBanner;
